import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Modal,
  TextField,
  Grid,
  Checkbox,
  Divider,
  Snackbar,
} from '@mui/material';
import { LineChart, Line, CartesianGrid, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import theme from './theme';
import categoriesData from './categories_dictionary_example.json';
import { BarChart, Bar, YAxis } from 'recharts';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


function TrainingCategorizationFeedbackLoopInterface() {
  const [currentCategory, setCurrentCategory] = useState(null);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [metricsModalOpen, setMetricsModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState('3');

  const pieColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const handleCategoryClick = (categoryKey) => {
    const list = categoriesData[categoryKey] || [];
    setCategoryDetails(list);
    const uniqueCategoriesList = [...new Set(list.map((item) => item.category))];
    setUniqueCategories(uniqueCategoriesList);
    setCurrentCategory(categoryKey);
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const handleCheckboxChange = (value) => {
    const updatedSelection = selectedCheckboxes.includes(value)
      ? selectedCheckboxes.filter((item) => item !== value)
      : [...selectedCheckboxes, value];

    setSelectedCheckboxes(updatedSelection);
    setToastOpen(updatedSelection.length > 0);
  };

  const clearAllSelected = () => {
    setSelectedCheckboxes([]);
    setToastOpen(false);
  };

  const handleToastFeedbackClick = () => {
    setFeedbackModalOpen(true);
  };

  const handleFeedbackSubmit = () => {
    setFeedbackModalOpen(false);
    alert('Thank you for your feedback!');
    setFeedback('');
  };

  const generateRandomData = () => [
    { name: 'True Positives', value: Math.floor(Math.random() * 100) + 1 },
    { name: 'False Positives', value: Math.floor(Math.random() * 100) + 1 },
    { name: 'False Negatives', value: Math.floor(Math.random() * 100) + 1 },
    { name: 'Unknown', value: Math.floor(Math.random() * 100) + 1 },
  ];

  const generateConfidenceScore = () => Math.floor(Math.random() * 66) + 30;

  const data = [
    { month: "Aug '24", errors: 15 },
    { month: "Jul '24", errors: 12 },
    { month: "Jun '24", errors: 8 },
  ];

  const generateStackedBarData = (months) => {
    const labels = ['True Positives', 'False Positives', 'False Negatives', 'Unknown'];
    return Array.from({ length: months }, (_, i) => {
      const month = new Date();
      month.setMonth(month.getMonth() - (months - 1) + i);
      const monthLabel = month.toLocaleString('default', { month: 'short', year: '2-digit' });
  
      const barData = {};
      labels.forEach((label) => {
        barData[label] = Math.floor(Math.random() * 50) + 10;
      });
      return { month: monthLabel, ...barData };
    });
  };
  
  const stackedBarData = generateStackedBarData(parseInt(dateRange));  

  const generateConfidenceTrendData = (months) => {
    return Array.from({ length: months }, (_, i) => {
      const month = new Date();
      month.setMonth(month.getMonth() - (months - 1) + i);
      const monthLabel = month.toLocaleString('default', { month: 'short', year: '2-digit' });
  
      return {
        month: monthLabel,
        confidence: Math.floor(Math.random() * 66) + 30, // Random confidence score
      };
    });
  };
  
  const [confidenceTrendData, setConfidenceTrendData] = useState(generateConfidenceTrendData(parseInt(dateRange)));
  

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
        <Button variant="outlined" onClick={() => setMetricsModalOpen(true)}>
          Data Definitions
        </Button>
      </Box>

        <Typography variant="h5" gutterBottom>
          Categorization Feedback
        </Typography>

        {!currentCategory ? (
          <Box>
            <Grid container spacing={2}>
              {Object.keys(categoriesData).map((categoryKey, index) => {
                const categoryList = categoriesData[categoryKey];
                const uniqueCategoriesCount = new Set(
                  categoryList.map((item) => item.category)
                ).size;

                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card onClick={() => handleCategoryClick(categoryKey)} sx={{ cursor: 'pointer' }}>
                      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {(() => {
                          // Generate dummy data such that the sum equals the number of values for the card
                          const totalValues = categoryList.length;
                          const truePositives = Math.floor((Math.random() * totalValues) / 3); // Random slice
                          const falsePositives = Math.floor((Math.random() * totalValues) / 3); // Random slice
                          const falseNegatives = Math.floor((Math.random() * totalValues) / 3); // Random slice
                          const unknown = totalValues - (truePositives + falsePositives + falseNegatives); // Ensure sum matches
                          const pieData = [
                            { name: 'True Positives', value: truePositives },
                            { name: 'False Positives', value: falsePositives },
                            { name: 'False Negatives', value: falseNegatives },
                            { name: 'Unknown', value: unknown },
                          ];

                          const confidenceScore = generateConfidenceScore(); // Generate confidence score

                          return (
                            <>
                              <PieChart width={150} height={150}>
                                <Pie
                                  data={pieData}
                                  dataKey="value"
                                  nameKey="name"
                                  cx="50%"
                                  cy="50%"
                                  outerRadius={60}
                                >
                                  {pieData.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={entry.name === 'Unknown' ? '#808080' : pieColors[index % pieColors.length]}
                                    />
                                  ))}
                                </Pie>
                                <Tooltip
                                  formatter={(value, name) => {
                                    const total = pieData.reduce((acc, item) => acc + item.value, 0);
                                    const percentage = ((value / total) * 100).toFixed(1);
                                    return [`${value} (${percentage}%)`, name];
                                  }}
                                />
                              </PieChart>
                              <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
                                 {capitalizeFirstLetter(categoryKey.replace('_list', '').replace(/_/g, ' '))}
                              </Typography>
                              <Typography variant="body2">
                                Values: {totalValues}
                              </Typography>
                              <Typography variant="body2">
                                Categories: {uniqueCategoriesCount}
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                                Confidence Score: {confidenceScore}%
                              </Typography>
                            </>
                          );
                        })()}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : (
          <Box>
            <Button onClick={() => setCurrentCategory(null)} variant="outlined" sx={{ marginBottom: 2 }}>
              Back to Categories
            </Button>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  AI categorizations for {currentCategory.replace('_list', '').replace(/_/g, ' ')} values
                </Typography>
                <Box
                  sx={{
                    maxHeight: 200,
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    padding: 2,
                  }}
                >
                  {uniqueCategories.map((uniqueCategory, index) => (
                    <Typography key={index} variant="body1">
                      {uniqueCategory}
                    </Typography>
                  ))}
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setFeedbackModalOpen(true)}
                  sx={{ marginTop: 2 }}
                >
                  Provide Feedback on these categories
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" gutterBottom>
                  Total Feedback: 27
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2 }}>
                  <Typography variant="body2" sx={{ marginRight: 1 }}>
                    Date Range:
                  </Typography>
                  <Select
                    value={dateRange}
                    onChange={(e) => {
                      const newRange = parseInt(e.target.value);
                      setDateRange(newRange);
                      setConfidenceTrendData(generateConfidenceTrendData(newRange));
                    }}
                    size="small"
                  >
                    <MenuItem value="3">3 months</MenuItem>
                    <MenuItem value="6">6 months</MenuItem>
                    <MenuItem value="12">1 year</MenuItem>
                  </Select>
                </Box>
                <Typography variant="h6" gutterBottom>
                  Error trend over time
                </Typography>
                <ResponsiveContainer width="100%" height={200}>
                  <BarChart data={stackedBarData}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="True Positives" stackId="a" fill="#0088FE" />
                    <Bar dataKey="False Positives" stackId="a" fill="#00C49F" />
                    <Bar dataKey="False Negatives" stackId="a" fill="#FFBB28" />
                    <Bar dataKey="Unknown" stackId="a" fill="#FF8042" />
                  </BarChart>
                </ResponsiveContainer>
                <Typography variant="h6" gutterBottom>
                  Confidence trend over time
                </Typography>
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={confidenceTrendData}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="confidence" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              AI categorization of {currentCategory.replace('_list', '').replace(/_/g, ' ')} values
            </Typography>
            <Box>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2" fontWeight="bold">
                    Value
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" fontWeight="bold">
                    AI Chosen Category
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" fontWeight="bold">
                    Give Feedback
                  </Typography>
                </Grid>
              </Grid>
              {categoryDetails.map((item, index) => (
                <Box key={index} my={1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <Typography variant="body2">{item.value}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">{item.category}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Checkbox
                        onChange={() => handleCheckboxChange(item.value)}
                        checked={selectedCheckboxes.includes(item.value)}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ backgroundColor: '#ccc', marginTop: 1 }} />
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {/* Feedback Modal */}
        <Modal
          open={feedbackModalOpen}
          onClose={() => setFeedbackModalOpen(false)}
          aria-labelledby="feedback-modal-title"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              width: '100%',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
          >
            <Typography id="feedback-modal-title" variant="h6" gutterBottom>
              Provide Feedback
            </Typography>
            <TextField
              label="Your Feedback"
              multiline
              rows={4}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              fullWidth
            />
            <Box mt={2} textAlign="right">
              <Button onClick={handleFeedbackSubmit} variant="contained">
                Submit
              </Button>
            </Box>
            {selectedCheckboxes.length > 0 && (
              <Box mt={4}>
                <Typography variant="h6">Selected Values</Typography>
                {selectedCheckboxes.map((value, idx) => {
                  const selectedItem = categoryDetails.find((item) => item.value === value);
                  return (
                    <Box key={idx} mb={2}>
                      <Typography variant="body2">
                        <strong>Value:</strong> {selectedItem?.value}
                      </Typography>
                      <Typography variant="body2">
                        <strong>AI-chosen Category:</strong> {selectedItem?.category}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Modal>

        {/* Snackbar for Toast Menu */}
        {toastOpen && (
          <Snackbar
            open={toastOpen}
            message={
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleToastFeedbackClick}
              >
                Give feedback on {selectedCheckboxes.length} selected value
                {selectedCheckboxes.length > 1 ? 's' : ''}
              </span>
            }
            action={
              <Button
                color="inherit"
                size="small"
                onClick={clearAllSelected}
              >
                Clear All Selected
              </Button>
            }
          />
        )}
        <Modal
          open={metricsModalOpen}
          onClose={() => setMetricsModalOpen(false)}
          aria-labelledby="metrics-modal-title"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              width: '100%',
            }}
          >
            <Typography id="metrics-modal-title" variant="h6" gutterBottom>
              Metric Definitions
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>True positives:</strong> Number of correct predictions for the category.
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>False positives:</strong> Number of incorrect predictions labeled as the category.
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>False negatives:</strong> Number of items that should have been labeled as the category but were not.
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Unknowns:</strong> Number of items that were not reviewed.
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Average Confidence:</strong> The average AI-assigned confidence value to chosen values in that category.
            </Typography>
            <Box mt={2} textAlign="right">
              <Button onClick={() => setMetricsModalOpen(false)} variant="contained">
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

      </Box>
    </ThemeProvider>
  );
}

export default TrainingCategorizationFeedbackLoopInterface;
