import React, { useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  Typography,
  Button,
  Modal,
  TextField,
  Grid,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import theme from './theme';
import mockCustomerData from './MockCustomerStory.json'; // Example JSON data

function TemplatePage() {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');

  const handleCustomerChange = (event) => {
    const customer = mockCustomerData.find(
      (data) => data.customer_name === event.target.value
    );
    setSelectedCustomer(customer);
  };

  const handleThumbsDown = () => {
    setFeedbackModalOpen(true);
  };

  const handleFeedbackSubmit = () => {
    setFeedbackModalOpen(false);
    alert('Thank you for your feedback to train the AI.');
  };

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <Box sx={{ paddingLeft: 2, borderLeft: '1px solid gray' }}>
          {value.map((item, index) => (
            <Typography key={index} variant="body2">
              {typeof item === 'object' ? JSON.stringify(item) : item}
            </Typography>
          ))}
        </Box>
      );
    } else if (typeof value === 'object') {
      return (
        <Box sx={{ paddingLeft: 2, borderLeft: '1px solid gray' }}>
          {Object.entries(value).map(([subKey, subValue]) => (
            <Typography key={subKey} variant="body2">
              {subKey}: {renderValue(subValue)}
            </Typography>
          ))}
        </Box>
      );
    } else {
      return value;
    }
  };

  // Configuration object for buttons
  const actionConfig = {
    example_field_name1: { buttons: ['👎'], showAddButton: false }, // No Add button
    example_field_name2: { buttons: [], showAddButton: false }, // No buttons at all
    filename: { buttons: [], showAddButton: false },
    processing_status: { buttons: [], showAddButton: false },
    customer_name: { buttons: [], addLabel: 'Modify name', showAddButton: true },
    conversation_date: { buttons: [], addLabel: 'Modify date', showAddButton: true },
    conversation_source: { buttons: [], addLabel: 'Modify source', showAddButton: true },
    about_me: { addLabel: 'Modify', buttons: ['👍', '👎'], showAddButton: true },
    segmentation_data: { buttons: ['👍', '👎'], showAddButton: false },
    trigger: { addLabel: 'Add', buttons: [], showAddButton: false },
    trigger_category: { addLabel: 'Add Trigger', buttons: ['👍', '👎'], showAddButton: false },
    solution_choice: { addLabel: 'Add', buttons: [], showAddButton: false },
    solution_choice_category: { addLabel: 'Add Trigger', buttons: ['👍', '👎'], showAddButton: false },
    satisfaction_level: { buttons: ['👍', '👎'], showAddButton: false },
    satisfaction_level_justification: { buttons: [], showAddButton: false },
    satisfaction_level_quote: { buttons: [], showAddButton: false },
    
    positives_of_solution_choice: { addLabel: 'Add Positive', buttons: ['👍', '👎'], showAddButton: true },
    drawbacks_of_solution_choice: { addLabel: 'Add Drawback', buttons: ['👍', '👎'], showAddButton: true },

    current_solution_still_used_in_these_situations: { buttons: [], showAddButton: false },

    not_used_in_these_situations: { buttons: [], showAddButton: false },

    previous_solution: { buttons: [], showAddButton: false },

    previous_solution_category: { addLabel: 'Add', buttons: ['👍', '👎'], showAddButton: false },

    reasons_not_good_enough: { addLabel: 'Add Reason', buttons: ['👍', '👎'], showAddButton: true },

    stickiness_level_of_previous_solution: { addLabel: 'Add', buttons: ['👍', '👎'], showAddButton: false },

    stickiness_level_of_previous_solution_justification: { buttons: [], showAddButton: false },

    stickiness_level_of_previous_solution_quote: { buttons: [], showAddButton: false },

    stickiness_level_of_previous_solution_timestamps: { buttons: [], showAddButton: false },

    considered_solutions: { addLabel: 'Add Solution', buttons: ['👍', '👎'], showAddButton: true },

    jobs_to_be_done: { addLabel: 'Add Job To Be Done (Need)', buttons: ['👍', '👎'], showAddButton: true },

    miscellaneous_insights: { addLabel: 'Add Insight', buttons: ['👍', '👎'], showAddButton: true },

    
    // Default fallback
    default: { addLabel: 'Add Insight', buttons: ['👍', '👎'], showAddButton: true },
  };
  
  const renderButtons = (key) => {
    const config = actionConfig[key] || actionConfig.default;
  
    // If no buttons should be shown at all
    if (!config.buttons.length && !config.showAddButton) {
      return null;
    }
  
    return (
      <Box>
        {/* Render feedback buttons based on configuration */}
        {config.buttons.includes('👍') && (
          <Button
            variant="contained"
            color="success"
            onClick={() =>
              alert(`You’ve trained the AI that ${key} was correctly categorized.`)
            }
            sx={{ marginRight: 1 }}
          >
            👍
          </Button>
        )}
        {config.buttons.includes('👎') && (
          <Button
            variant="contained"
            color="error"
            onClick={handleThumbsDown}
            sx={{ marginRight: 1 }}
          >
            👎
          </Button>
        )}
        {/* Render the "Add" button if enabled */}
        {config.showAddButton && (
          <Button variant="contained">{config.addLabel}</Button>
        )}
      </Box>
    );
  };
  

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Conversation Insight Validation Workspace
        </Typography>

        {/* Transcript Selection */}
        <Typography variant="h6">Choose a Transcript</Typography>
        <Select
          value={selectedCustomer?.customer_name || ''}
          onChange={handleCustomerChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="" disabled>
            Select a Customer
          </MenuItem>
          {mockCustomerData.map((data) => (
            <MenuItem key={data.customer_name} value={data.customer_name}>
              {data.customer_name}
            </MenuItem>
          ))}
        </Select>

        {/* Customer Details */}
        {selectedCustomer && (
          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h5" gutterBottom>
              Customer Story
            </Typography>
            <Grid container spacing={2}>
              {Object.keys(selectedCustomer).map((key) => (
                <React.Fragment key={key}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant="body1" gutterBottom>
                        <strong>{key}:</strong>
                      </Typography>
                      {renderValue(selectedCustomer[key])}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {renderButtons(key)}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        )}

        {/* Feedback Modal */}
        <Modal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: 400,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Provide Feedback
            </Typography>
            <TextField
              multiline
              rows={4}
              fullWidth
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
            <Box sx={{ marginTop: 2, textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFeedbackSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}

export default TemplatePage;
