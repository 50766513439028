import React, { useContext, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  Modal,
  TextField,
  MenuItem,
  Button,
} from '@mui/material';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataAndFilterContext } from './context/DataAndFilterContext';
import NavBar from './components/NavBar';
import FilterBar from './components/FilterBar';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';

const InsightDetails = () => {
  const { filteredData } = useContext(DataAndFilterContext);
  const [editModalOpen, setEditModalOpen] = useState(false);

  // Dummy data for revenue impact section
  const dummyRevenueData = {
    revenueImpact: "$$$$",
    aiEstimatedRevenue: "$500k ARR",
    salesforceRevenue: "$550k ARR",
  };

  // Close the modal
  const closeModal = () => {
    setEditModalOpen(false);
  };

  // Get chart data
  const chartData = filteredData.map((file) => ({
    month: new Date(file.conversation_date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
    }),
    conversations: file.miscellaneous_insights?.length || 0,
    satisfaction: file.satisfaction_level || 0,
  }));

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Insight Details
        </Typography>
        <FilterBar />
        <Box display="flex" mt={3}>
          {/* Left Column */}
          <Box flex={1} p={2} component={Paper}>
            <Typography variant="h6" gutterBottom>
              Revenue Impact
            </Typography>
            <Tooltip
              title="This score represents the revenue potential of solving this insight, ranging from $ (low) to $$$$$ (high)."
              arrow
            >
              <Typography variant="body1">Revenue Impact: {dummyRevenueData.revenueImpact}</Typography>
            </Tooltip>
            <Typography variant="body1">AI-Estimated Revenue Impact: {dummyRevenueData.aiEstimatedRevenue}</Typography>
            <Typography variant="body1">Salesforce-based Revenue: {dummyRevenueData.salesforceRevenue}</Typography>
            <Box mt={2}>
              <IconButton onClick={() => setEditModalOpen(true)}>
                <EditIcon />
              </IconButton>
              <IconButton>
                <AddCircleIcon />
              </IconButton>
            </Box>
          </Box>
          {/* Right Column */}
          <Box flex={2} p={2} component={Paper}>
            <Typography variant="h6" gutterBottom>
              Insights Chart
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData}>
                <XAxis dataKey="month" />
                <YAxis yAxisId="left" label={{ value: "# of Conversations", angle: -90, position: "insideLeft" }} />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{ value: "Avg Satisfaction", angle: -90, position: "insideRight" }}
                  domain={[1, 5]}
                />
                <Bar yAxisId="left" dataKey="conversations" fill="#8884d8" />
                <Line yAxisId="right" type="monotone" dataKey="satisfaction" stroke="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* Insights */}
        <Box mt={3}>
          {filteredData.map((file, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2 }}>
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                "{file.insights?.[0]?.insight_quote || "No quote available"}"
              </Typography>
              <Typography variant="body2">
                Summary: {file.insights?.[0]?.insight_summary || "No summary available"}
              </Typography>
              <Typography variant="body2">Type: {file.insights?.[0]?.insight_type || "N/A"}</Typography>
              <Typography variant="body2">Category: {file.insights?.[0]?.insight_category || "N/A"}</Typography>
            </Paper>
          ))}
        </Box>
      </Box>

      {/* Edit Revenue Impact Modal */}
      <Modal open={editModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Revenue Impact
          </Typography>
          <TextField label="Customers Affected" type="number" fullWidth margin="normal" defaultValue={50} />
          <TextField
            label="Average Revenue per Customer ($)"
            type="number"
            fullWidth
            margin="normal"
            defaultValue={1000}
          />
          <TextField
            label="Metric Impacted"
            select
            fullWidth
            margin="normal"
            defaultValue="Acquisition"
          >
            {["Acquisition", "Activation", "Retention", "Revenue"].map((metric) => (
              <MenuItem key={metric} value={metric}>
                {metric}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Estimated Impact on Metric (%)"
            type="number"
            fullWidth
            margin="normal"
            defaultValue={20}
          />
          <Typography variant="body2" mt={2}>
            ARR Potential: $100k
          </Typography>
          <Box mt={3} textAlign="right">
            <Button variant="contained" color="primary" onClick={closeModal}>
              Add to Report
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default InsightDetails;
