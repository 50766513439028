import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography, Box, Grid, IconButton, Tooltip } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';


const InterviewPage = () => {
  const { projectId } = useParams(); // Extract projectId from URL
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [muted, setMuted] = useState(false);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [aiMessage, setAiMessage] = useState("Welcome! The interview will start shortly.");
  const [transcript, setTranscript] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const navigate = useNavigate();


  const fetchAIResponse = async (inputText = null, audioFile = null) => {
    const formData = new FormData();
    if (inputText) {
      formData.append('text', inputText); // Correct key for input text
    }
    if (audioFile) {
      formData.append('audio', audioFile, 'response.webm'); // Attach audio file. The MediaRecorder API does not natively support MP3 encoding in most browsers, so recorded files cannot be directly encoded as MP3s using this API. You can transcode the file in the frontend using a library like ffmpeg.js.
    }
    if (threadId) {
      formData.append('thread_id', threadId);
    }
    formData.append('project_id', projectId); // Pass projectId

    try {
      const response = await fetch('/api/process-conversation', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const audioURL = URL.createObjectURL(await response.blob());
        const newThreadId = response.headers.get('updated_thread_id');
        const generatedText = response.headers.get('generated_text');

        if (newThreadId) setThreadId(newThreadId);

        setAiMessage(generatedText);
        setTranscript((prev) => [
          ...prev,
          { timestamp: new Date(), text: generatedText },
        ]);

        playAudio(audioURL);
      } else {
        console.error('Failed to fetch AI response:', response.status);
      }
    } catch (error) {
      console.error('Error during AI response fetch:', error);
    }
  };

  const playAudio = (audioURL) => {
    const audio = new Audio(audioURL);
    audioRef.current = audio;

    audio.play();
    audio.onended = () => {
      startRecording();
      setSubmitDisabled(false);
    };
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      console.log("Stopping MediaRecorder manually...");
      return new Promise((resolve) => {
        mediaRecorderRef.current.onstop = () => {
          console.log("MediaRecorder onstop event triggered");
          const chunks = mediaRecorderRef.current.chunks || []; // Use a ref-stored array to ensure data is accessible
          const recordedBlob = new Blob(chunks, { type: 'audio/webm' });
          console.log("Recording stopped. Blob created:", recordedBlob);
          console.log("Recorded Blob size:", recordedBlob.size, "bytes");
    
          if (recordedBlob.size === 0) {
            console.error("Recorded Blob is empty!");
          } else {
            setAudioBlob(recordedBlob); // Set the blob
            resolve(recordedBlob); // Resolve the promise with the blob
          }
        };
        mediaRecorderRef.current.stop();
        setIsRecording(false);
      });
    }
  };
  
  const handleSubmitResponse = async () => {
    try {
      const recordedBlob = await stopRecording(); // Wait for recording to stop
      console.log("Submitting audioBlob:", recordedBlob);
  
      if (!recordedBlob) {
        alert('No audio recorded!');
        return;
      }
  
      setSubmitDisabled(true);
      fetchAIResponse(null, recordedBlob); // Submit the blob
      setAudioBlob(null); // Clear the blob
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  
  const startRecording = async () => {
    try {
      console.log("Initializing audio recording...");
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      mediaRecorderRef.current.chunks = []; // Use a ref-stored array to accumulate data
  
      console.log("MediaRecorder created:", mediaRecorderRef.current);
  
      mediaRecorderRef.current.ondataavailable = (e) => {
        console.log("MediaRecorder ondataavailable event triggered");
        mediaRecorderRef.current.chunks.push(e.data);
      };
  
      mediaRecorderRef.current.onerror = (error) => {
        console.error("MediaRecorder error:", error);
      };
  
      mediaRecorderRef.current.onstart = () => {
        console.log("MediaRecorder started");
      };
  
      mediaRecorderRef.current.start();
      console.log("Recording started. MediaRecorder state:", mediaRecorderRef.current.state);
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };  

  const handleStartInterview = async () => {
    await fetchAIResponse('Hello'); // Send "Hello" for the initial message
    setInterviewStarted(true);
  };

  const handleStopInterview = async () => {
    setInterviewStarted(false);
    setAudioEnabled(false);

    try {
      const response = await fetch('/api/end_conversation', {
        method: 'POST',
        body: JSON.stringify({ thread_id: threadId }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        navigate('/thank-you');
      } else {
        const errorData = await response.json();
        console.error('Error ending conversation:', errorData);
        alert('Failed to end the conversation. Please try again.');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      alert('An error occurred while ending the conversation.');
    }
  };


  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: 3,
        background: 'linear-gradient(to bottom, #f5f5f5, #e0e0e0)',
        minHeight: '100vh',
      }}
    >
      {!interviewStarted ? (
        <>
          <Typography variant="h4">Welcome to Your AI Interview</Typography>
          <Typography variant="body1">
            This AI interviewer will guide you through a set of questions. Ensure your microphone and camera are enabled.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleStartInterview}>
            Start Interview
          </Button>
        </>
      ) : (
        <Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box
                ref={videoRef}
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '8px',
                  height: '300px',
                  display: videoEnabled ? 'block' : 'none',
                }}
              >
                {/* Video component placeholder */}
              </Box>
              {!videoEnabled && <Typography variant="body2" color="textSecondary">Video is off</Typography>}
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginTop: 2 }}>
                <Tooltip title="Toggle video on/off">
                  <IconButton
                    color={videoEnabled ? 'primary' : 'error'}
                    onClick={() => setVideoEnabled(!videoEnabled)}
                  >
                    {videoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Toggle microphone on/off">
                  <IconButton
                    color={muted ? 'error' : 'primary'}
                    onClick={() => setMuted(!muted)}
                  >
                    {muted ? <MicOffIcon /> : <MicIcon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Share screen">
                  <IconButton
                    color="secondary"
                    onClick={() => alert('Screen sharing not implemented yet!')}
                  >
                    <ScreenShareIcon />
                  </IconButton>
                </Tooltip>
              </Box>   
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '8px',
                  height: '300px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <Typography variant="h6" sx={{ padding: 2 }}>{aiMessage}</Typography>
              </Box>
            </Grid>
          </Grid>

          {/*
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="h5">Transcript</Typography>
            <Box sx={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid gray', padding: 2 }}>
              {transcript.map((entry, index) => (
                <Typography key={index} variant="body2">
                  [{new Date(entry.timestamp).toLocaleTimeString()}] {entry.text}
                </Typography>
              ))}
            </Box>
          </Box>
          */}
       
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitResponse}
            disabled={submitDisabled}
            sx={{ marginTop: 2 }}
          >
            Submit Response
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleStopInterview}
            startIcon={<ExitToAppIcon />}
            sx={{ marginLeft: 2 }}
          >
            Leave Interview
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default InterviewPage;
