import React, { useState } from 'react';
import { Box, Button, Typography, Stepper, Step, StepLabel, Card, CardContent, Checkbox, FormControlLabel } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import theme from './theme';

// Step components for the Wizard
const Introduction = ({ onNext }) => (
  <Box textAlign="center">
    <Typography variant="h5" gutterBottom>
      Welcome to the App
    </Typography>
    <Typography variant="body1" gutterBottom>
      This wizard will guide you through the setup process to define your goals, set up integrations, and learn how to train your AI.
    </Typography>
    <Button variant="contained" onClick={onNext}>
      Get Started
    </Button>
  </Box>
);

const DefineGoals = ({ onBack, onNext }) => {
  const [selectedGoals, setSelectedGoals] = useState([]);

  const handleGoalToggle = (goal) => {
    setSelectedGoals((prev) =>
      prev.includes(goal) ? prev.filter((g) => g !== goal) : [...prev, goal]
    );
  };

  const goals = [
    'Create competitive differentiation strategy',
    'Shortlist pain points in the user experience',
    'Identify the most important customer needs',
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        What key decisions do you aim to make using customer insights?
      </Typography>
      <Typography variant="body2" gutterBottom>
        This won’t limit the data available to you; it will just highlight certain data on your dashboard, which you can customize later.
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {goals.map((goal) => (
          <Card
            key={goal}
            variant={selectedGoals.includes(goal) ? 'outlined' : undefined}
            onClick={() => handleGoalToggle(goal)}
            sx={{ cursor: 'pointer', padding: 2 }}
          >
            <CardContent>
              <Typography>{goal}</Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button onClick={onBack}>Back</Button>
        <Box>
          <Button onClick={onNext} sx={{ marginRight: 1 }}>
            Do this later
          </Button>
          <Button variant="contained" onClick={onNext}>
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const SelectDataSources = ({ onBack, onNext }) => {
  const [integrations, setIntegrations] = useState({
    'Chorus.ai': false,
    Gong: false,
    Zendesk: false,
    'Google Drive': false,
  });

  const handleIntegrationToggle = (source) => {
    setIntegrations((prev) => ({
      ...prev,
      [source]: !prev[source],
    }));
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Which sources of customer conversations do you want to analyze?
      </Typography>
      <Typography variant="body2" gutterBottom>
        You can bring in your sales calls, research interviews, support chats/emails, and more.
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {Object.keys(integrations).map((source) => (
          <FormControlLabel
            key={source}
            control={
              <Checkbox
                checked={integrations[source]}
                onChange={() => handleIntegrationToggle(source)}
              />
            }
            label={source}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button onClick={onBack}>Back</Button>
        <Box>
          <Button onClick={onNext} sx={{ marginRight: 1 }}>
            Do this later
          </Button>
          <Button variant="contained" onClick={onNext}>
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const Completion = ({ onFinish }) => (
  <Box textAlign="center">
    <Typography variant="h5" gutterBottom>
      Setup Complete!
    </Typography>
    <Typography variant="body1" gutterBottom>
      You will need to wait until at least 10 conversations have been processed before you will start to see insights.
    </Typography>
    <Typography variant="body2" gutterBottom>
      In the meantime, feel free to explore our sandbox mode with sample data.
    </Typography>
    <Button variant="contained" onClick={onFinish}>
      View My Data
    </Button>
  </Box>
);

// Main Wizard Component
function WizardFlow() {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { label: 'Introduction', component: Introduction },
    { label: 'Define Goals', component: DefineGoals },
    { label: 'Select Data Sources', component: SelectDataSources },
    { label: 'Completion', component: Completion },
  ];

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const StepComponent = steps[activeStep].component;

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3, maxWidth: 600, margin: '0 auto' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ marginTop: 3 }}>
          <StepComponent
            onNext={handleNext}
            onBack={handleBack}
            onFinish={() => console.log('Wizard Finished')}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default WizardFlow;
