import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Typography, Button, Modal, TextField, Grid, Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import NavBar from './components/NavBar';
import theme from './theme';
import SettingsIcon from '@mui/icons-material/Settings';

const dummyData = {
  businessModel: "B2B",
  solutionType: ["Data Analytics"],
  arr: {
    current: 150000,
    goals: {
      threeMonth: 200000,
      oneYear: 300000,
    },
    customerSegments: [
      {
        segmentName: "Real Estate Investors",
        description: "Investors purchasing properties for Airbnb rentals.",
        tam: 100000000,
        sam: 10000000,
        som: 1000000,
        tamDescription: "All real estate investors",
        samDescription: "Real estate investors in the US",
        somDescription: "Short-term real estate investors in the US with 1+ properties",
        monetization: {
          monthlyRevenuePerCustomer: 50,
          averageMonths: 12,
        },
        conversionRates: {
          visitorToFreemium: 25,
          freemiumToPaying: 2,
        },
        needs: [
          { description: "Accurately project market trends", value: 5000 },
          { description: "Compare property types within a market", value: 5000 },
        ],
        painPoints: [
          { description: "Lack of local pricing insights", value: 3000 },
          { description: "Hard to project future performance of markets", value: 6000 },
          { description: "Lack of competitor property comparison tools", value: 2000 },
        ],
      },
      {
        segmentName: "Short Term Rental Hosts",
        description: "STR hosts managing one or more properties.",
        tam: 5000000,
        sam: 1750000,
        som: 1000000,
        tamDescription: "All short-term rental property hosts",
        samDescription: "Airbnb hosts in the US",
        somDescription: "Airbnb hosts in the US who don't use property management services",
        monetization: {
          monthlyRevenuePerCustomer: 25,
          averageMonths: 24,
        },
        conversionRates: {
          visitorToFreemium: 20,
          freemiumToPaying: 3,
        },
        needs: [
          { description: "Daily smart pricing for my property", value: 3000 },
          { description: "Competitor pricing and property comparisons", value: 2000 },
        ],
        painPoints: [
          { description: "Lack of local pricing insights", value: 3000 },
          { description: "Lack of competitor property comparison tools", value: 2000 },
        ],
      },    
    ],
  },
};


function StrategicReport() {
  const [modalOpen, setModalOpen] = useState(false);
  const [businessModel, setBusinessModel] = useState(dummyData.businessModel);
  const [solutionType, setSolutionType] = useState(dummyData.solutionType);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const handleBusinessModelChange = (event) => setBusinessModel(event.target.value);
  const handleSolutionTypeChange = (event) => setSolutionType(event.target.value);

  const calculatePenetrationRate = (segment) => {
    const { tam, monetization } = segment;
    const annualARRGoal = dummyData.arr.goals.oneYear; // Access from dummyData.arr
    const customerLifetimeValue = monetization.monthlyRevenuePerCustomer * monetization.averageMonths;
    return ((annualARRGoal / customerLifetimeValue) / tam) * 100;
  };
  
  const renderCustomerSegment = (segment) => {
    const penetrationRate = calculatePenetrationRate(segment);
  
    // Prepare data for Recharts
    const chartData = [
      { name: 'Current', ARR: dummyData.arr.current }, // Access from dummyData.arr
      { name: '3-Month Goal', ARR: dummyData.arr.goals.threeMonth }, // Access from dummyData.arr
      { name: '1-Year Goal', ARR: dummyData.arr.goals.oneYear }, // Access from dummyData.arr
    ];
  
    return (
      <Box key={segment.segmentName} sx={{ marginY: 3 }}>
        <Typography variant="h5">Customer Segment: {segment.segmentName}</Typography>
        <br />
                <Grid container spacing={2}>
          {/* Left Column: Line Chart */}
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(value) => `$${value}`} />
                <Tooltip formatter={(value) => `$${value}`} />
                <Line type="monotone" dataKey="ARR" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
  
          {/* Right Column: Details */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">ARR:</Typography>
            <Typography>Current ARR: ${dummyData.arr.current}</Typography>
            <Typography>3-Month Goal ARR: ${dummyData.arr.goals.threeMonth}</Typography>
            <Typography>1-Year Goal ARR: ${dummyData.arr.goals.oneYear}</Typography>
  
            <Typography variant="h6">Monetization Variables:</Typography>
            <Typography>${segment.monetization.monthlyRevenuePerCustomer} per month</Typography>
            <Typography>{segment.monetization.averageMonths} average months per customer</Typography>
            <Typography>${segment.monetization.monthlyRevenuePerCustomer * segment.monetization.averageMonths} customer lifetime value</Typography>
  
            <Typography variant="h6">Conversion Rates:</Typography>
            <Typography>{segment.conversionRates.visitorToFreemium}% - Visitor-to-Freemium Conversion</Typography>
            <Typography>{segment.conversionRates.freemiumToPaying}% - Freemium-to-Paying Conversion</Typography>
  
            <Typography variant="h6">Top Needs:</Typography>
            {segment.needs.map((need, index) => (
              <Typography key={index}>{need.description} - Value: ${need.value}</Typography>
            ))}
  
            <Typography variant="h6">Top Pain Points:</Typography>
            {segment.painPoints.map((pain, index) => (
              <Typography key={index}>{pain.description} - Value: ${pain.value}</Typography>
            ))}
  
            <Typography variant="h6">Penetration Rate Analysis:</Typography>
            <Typography>{penetrationRate.toFixed(2)}% penetration rate needed to achieve goal ARR</Typography>
            <Typography sx={{ color: 'green' }}>Sanity-Check: Reasonable based on benchmark penetration rates for your business model and solution type.</Typography>
          </Grid>
        </Grid>
        <br />
        <hr />
      </Box>
    );
  };

  const recommendations = [
    {
      title: "Focus on Real Estate Investors",
      content:
        "This segment has a higher TAM and SAM compared to Short Term Rental Hosts. Consider investing in tools to help investors project market trends more effectively and compare property types in granular detail.",
    },
    {
      title: "Improve Pain Points for Short Term Rental Hosts",
      content:
        "Address the lack of competitor comparison tools and local pricing insights. Enhancing these features can significantly improve satisfaction and drive higher conversions.",
    },
    {
      title: "Explore New Segments",
      content:
        "Consider targeting vacation rental property managers or real estate agents who might need dynamic pricing insights. This could expand the customer base while leveraging existing product features.",
    },
    {
      title: "Penetration Rate Analysis",
      content:
        "Focus on improving customer acquisition strategies to reach the penetration rate targets. Offer free trials or targeted promotions to increase freemium conversions.",
    },
  ];

  const renderRecommendations = () =>
    recommendations.map((rec, index) => (
      <Box
        key={index}
        sx={{
          marginBottom: 2,
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          {rec.title}
        </Typography>
        <Typography>{rec.content}</Typography>
      </Box>
    ));
  

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Business Model Strategic Report
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          The AI combines its analysis of your customer conversations with your feedback on this page to generate this strategic report.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: 3 }}>
          <Button onClick={handleModalOpen} variant="outlined" startIcon={<SettingsIcon />}>
            Configure
          </Button>
        </Box>

        {dummyData.arr.customerSegments.map((segment) => renderCustomerSegment(segment))}

        {/* Recommendations Section */}
        <Box sx={{ marginTop: 5 }}>
          <Typography variant="h5" gutterBottom>
            Recommendations
          </Typography>
          {renderRecommendations()}
        </Box>

        {/* Modal for Configuration */}
        <Modal open={modalOpen} onClose={handleModalClose}>
            <Box
                sx={{
                    padding: 4,
                    background: 'white',
                    margin: 'auto',
                    top: '10%',
                    left: '25%',
                    width: '50%',
                    maxHeight: '80vh', // Limit the height to 80% of the viewport height
                    overflowY: 'auto', // Enable scrolling for overflowed content
                    position: 'absolute',
                    borderRadius: 2,
                }}
            >
            <Typography variant="h6" gutterBottom>
                Business Model
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                Configure these settings to help the AI accurately assess your customer strategy.
            </Typography>

            {/* Business Model Fields */}
            <FormControl fullWidth sx={{ marginY: 2 }}>
                <InputLabel>Business Model</InputLabel>
                <Select value={businessModel} onChange={handleBusinessModelChange}>
                <MenuItem value="B2B">B2B</MenuItem>
                <MenuItem value="B2C">B2C</MenuItem>
                <MenuItem value="Enterprise">Enterprise</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginY: 2 }}>
                <InputLabel>Solution Type</InputLabel>
                <Select
                multiple
                value={solutionType}
                onChange={handleSolutionTypeChange}
                renderValue={(selected) => selected.join(', ')}
                >
                <MenuItem value="Data Analytics">
                    <Checkbox checked={solutionType.indexOf('Data Analytics') > -1} />
                    <ListItemText primary="Data Analytics" />
                </MenuItem>
                <MenuItem value="Customer Insights">
                    <Checkbox checked={solutionType.indexOf('Customer Insights') > -1} />
                    <ListItemText primary="Customer Insights" />
                </MenuItem>
                <MenuItem value="Market Trends">
                    <Checkbox checked={solutionType.indexOf('Market Trends') > -1} />
                    <ListItemText primary="Market Trends" />
                </MenuItem>
                </Select>
            </FormControl>

            <br />
            <br />
            <hr />
            <br />

            <Typography variant="h6" gutterBottom>
                ARR Goals
            </Typography>
            <TextField
                fullWidth
                sx={{ marginY: 2 }}
                label="Current ARR ($)"
                defaultValue={dummyData.arr.current}
            />
            <TextField
                fullWidth
                sx={{ marginY: 2 }}
                label="3-Month ARR Goal ($)"
                defaultValue={dummyData.arr.goals.threeMonth}
            />
            <TextField
                fullWidth
                sx={{ marginY: 2 }}
                label="1-Year ARR Goal ($)"
                defaultValue={dummyData.arr.goals.oneYear}
            />

            {/* Customer Segment Fields */}
            {dummyData.arr.customerSegments.map((segment, index) => (
                <Box key={index} sx={{ marginY: 3, borderTop: '1px solid #ccc', paddingTop: 2 }}>
                <Typography variant="h6">Customer segment: {segment.segmentName}</Typography>
                {segment.description}
                <br />
                <Typography variant="subtitle1" sx={{ marginY: 1 }}>
                    Market Sizing (TAM, SAM, SOM):
                </Typography>
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="TAM (Total Addressable Market) description"
                    defaultValue={segment.tam}
                />
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="TAM Size"
                    defaultValue={segment.tamDescription}
                />
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="SAM (Serviceable Available Market) description"
                    defaultValue={segment.sam}
                />
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="SAM Size"
                    defaultValue={segment.samDescription}
                />
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="SOM (Serviceable Obtainable Market) description"
                    defaultValue={segment.som}
                />
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="SOM Size"
                    defaultValue={segment.somDescription}
                />

                {/* Monetization Variables */}
                <Typography variant="subtitle1" sx={{ marginY: 1 }}>
                    Monetization Variables:
                </Typography>
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="Average Revenue per Month ($)"
                    defaultValue={segment.monetization.monthlyRevenuePerCustomer}
                />
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="Average Months per Customer"
                    defaultValue={segment.monetization.averageMonths}
                />
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="Customer Lifetime Value ($)"
                    defaultValue={
                    segment.monetization.monthlyRevenuePerCustomer * segment.monetization.averageMonths
                    }
                    disabled
                />

                {/* Conversion Rate Variables */}
                <Typography variant="subtitle2" sx={{ marginY: 1 }}>
                    Conversion Rate Variables:
                </Typography>
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="Visitor-to-Freemium Conversion (%)"
                    defaultValue={segment.conversionRates.visitorToFreemium}
                />
                <TextField
                    fullWidth
                    sx={{ marginY: 1 }}
                    label="Freemium-to-Paying Conversion (%)"
                    defaultValue={segment.conversionRates.freemiumToPaying}
                />

                {/* Needs */}
                <Typography variant="subtitle2" sx={{ marginY: 1 }}>
                    Top Needs:
                </Typography>
                {segment.needs.map((need, needIndex) => (
                    <Box key={needIndex} sx={{ marginY: 1 }}>
                    <TextField
                        fullWidth
                        sx={{ marginY: 1 }}
                        label="Need Description"
                        defaultValue={need.description}
                    />
                    <TextField
                        fullWidth
                        sx={{ marginY: 1 }}
                        label="Value to Customer ($)"
                        defaultValue={need.value}
                    />
                    </Box>
                ))}

                {/* Pain Points */}
                <Typography variant="subtitle2" sx={{ marginY: 1 }}>
                    Top Pain Points:
                </Typography>
                {segment.painPoints.map((painPoint, painIndex) => (
                    <Box key={painIndex} sx={{ marginY: 1 }}>
                    <TextField
                        fullWidth
                        sx={{ marginY: 1 }}
                        label="Pain Point Description"
                        defaultValue={painPoint.description}
                    />
                    <TextField
                        fullWidth
                        sx={{ marginY: 1 }}
                        label="Value to Customer ($)"
                        defaultValue={painPoint.value}
                    />
                    </Box>
                ))}
                </Box>
            ))}

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" onClick={handleModalClose}>
                Cancel
                </Button>
                <Button variant="contained" onClick={handleModalClose}>
                Submit
                </Button>
            </Box>
            </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}

export default StrategicReport;
