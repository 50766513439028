import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Typography, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import theme from './theme';
import NavBar from './components/NavBar';
import { ComposedChart, Line } from 'recharts';

const TrainingActivity = () => {
  const [currentPage, setCurrentPage] = useState('Organization-Level Stats');
  const [sortConfig, setSortConfig] = useState({ key: 'aiConfidenceScore', direction: 'desc' });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Placeholder data for horizontal bar chart
  const categoryBarData = [
    { category: 'Triggers', corrections: 50, errorRate: 15 },
    { category: 'Situations', corrections: 30, errorRate: 20 },
    { category: 'Jobs', corrections: 40, errorRate: 10 },
    { category: 'Desired Outcomes', corrections: 20, errorRate: 25 },
    { category: 'Insights', corrections: 35, errorRate: 5 },
    { category: 'Insight Product Areas', corrections: 25, errorRate: 18 },
    { category: 'Solution Choices', corrections: 45, errorRate: 12 },
    { category: 'Positives', corrections: 30, errorRate: 8 },
    { category: 'Drawbacks', corrections: 20, errorRate: 22 },
    { category: 'Previous Solutions', corrections: 15, errorRate: 30 },
    { category: 'Considered Solutions', corrections: 25, errorRate: 28 },
    { category: 'Reasons Not Chosen', corrections: 10, errorRate: 35 },
  ];

  const categoryStats = categoryBarData.map((item) => ({
    category: item.category,
    aiConfidenceScore: parseFloat((Math.random() * 0.5 + 0.5).toFixed(2)), // Dummy confidence scores
    humanFeedbackContributions: item.corrections,
    changeInErrorRate: Math.random() > 0.5 ? '10% decrease' : '5% increase',
  }));

  const sortedCategoryStats = [...categoryStats].sort((a, b) => {
    const direction = sortConfig.direction === 'asc' ? 1 : -1;
    if (a[sortConfig.key] < b[sortConfig.key]) return -1 * direction;
    if (a[sortConfig.key] > b[sortConfig.key]) return 1 * direction;
    return 0;
  });

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const leaderboardData = [
    { user: 'Sarah Masterson', corrections: 60 },
    { user: 'John Doe', corrections: 55 },
    { user: 'Emily Rose', corrections: 50 },
    { user: 'Chris Taylor', corrections: 45 },
    { user: 'Nina Wilson', corrections: 40 },
    { user: 'David Kim', corrections: 35 },
    // Add more dummy names as needed
  ].sort((a, b) => b.corrections - a.corrections);

  const achievements = [
    {
      title: 'AI Expert: 100 corrections',
      count: 15,
      recentEarners: ['Sarah M.', 'John D.', 'Emily R.'],
    },
    {
      title: 'Category Specialist: Improved 3 categories significantly',
      count: 10,
      recentEarners: ['Chris T.', 'Nina W.', 'David K.'],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3, maxWidth: 1200, margin: '0 auto' }}>
        <Typography variant="h4" gutterBottom>
          Human-in-the-Loop Training Activity
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, marginBottom: 3 }}>
          {['Organization-Level Stats', 'Feedback Leaderboard', 'My Achievements and Badges'].map((page) => (
            <Button
              key={page}
              variant={currentPage === page ? 'contained' : 'outlined'}
              color={currentPage === page ? 'primary' : 'default'}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Button>
          ))}
        </Box>

        {currentPage === 'Organization-Level Stats' && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Organization-Level Stats
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
              <ComposedChart
                layout="vertical"
                width={900}
                height={400}
                data={categoryBarData}
                margin={{ top: 20, right: 30, left: 50, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="category" width={200} />
                <Tooltip />
                <Legend />
                <Bar dataKey="corrections" fill="#8884d8" name="Corrections" />
                <Line
                  type="monotone"
                  dataKey="errorRate"
                  stroke="#ff7300"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  name="Error Rate"
                />
              </ComposedChart>
            </Box>

            <Typography variant="body1" sx={{ marginTop: 3 }}>
              Category Stats:
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'aiConfidenceScore'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('aiConfidenceScore')}
                      >
                        AI Confidence Score
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'humanFeedbackContributions'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('humanFeedbackContributions')}
                      >
                        Human Feedback Contributions
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'changeInErrorRate'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('changeInErrorRate')}
                      >
                        Change in Error Rate
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedCategoryStats.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.aiConfidenceScore}</TableCell>
                      <TableCell>{row.humanFeedbackContributions}</TableCell>
                      <TableCell>{row.changeInErrorRate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {currentPage === 'Feedback Leaderboard' && (
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography variant="h6" gutterBottom>
                Feedback Leaderboard
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Corrections</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaderboardData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.user}</TableCell>
                        <TableCell>{row.corrections}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                Achievements and Badges
              </Typography>
              {achievements.map((achievement, index) => (
                <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
                  <Typography variant="h6">{achievement.title}</Typography>
                  <Typography>Earned by {achievement.count} people</Typography>
                  <Typography variant="body2">
                    Recent Earners: {achievement.recentEarners.join(', ')}
                  </Typography>
                </Paper>
              ))}
            </Grid>
          </Grid>
        )}

        {currentPage === 'My Achievements and Badges' && (
          <Box>
            <Typography variant="h6" gutterBottom>
              My Achievements and Badges
            </Typography>
            <Grid container spacing={3}>
              {achievements.map((achievement, index) => (
                <Grid item xs={4} key={index}>
                  <Paper sx={{ padding: 2 }}>
                    <Typography variant="h6">{achievement.title}</Typography>
                    <Typography>Earned by you</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default TrainingActivity;
