import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FilterProvider } from './context/DataAndFilterContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GlobalLoadingProvider, useGlobalLoading } from './context/GlobalLoadingContext';
import CircularProgressWithLabel from './components/CircularProgressWithLabel';
import MarketingPage from './marketing/MarketingPage';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import PostLoginPage from './Dashboard';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ManageData from './ManageData';
import PersonaProfiles from './PersonaProfiles';
import PersonaProfile from './PersonaProfile';
import CustomerConversationsPage from './CustomerConversationsPage';
import ChosenSolutionsPage from './ChosenSolutionsPage';
import TriggersPage from './TriggersPage';
import PreviousSolutionsPage from './PreviousSolutionsPage';
import ConsideredSolutionsPage from './ConsideredSolutionsPage';
import CustomerNeedsPage from './CustomerNeedsPage';
import UsageInsightsPage from './UsageInsightsPage';
import InsightDetails from './InsightDetails';
import CustomerJourneyMapPage from './CustomerJourneyMapPage';
import JobMapPage from './JobMapPage';
import ManageProjects from './ai-interviewer/ManageProjects';
import ProjectCreationFlow from './ai-interviewer/ProjectCreationFlow';
import ProjectDetails from './ai-interviewer/ProjectDetails';
import InterviewPage from './ai-interviewer/InterviewPage';
import IntegrationsPage from './IntegrationsPage';
import IntegrationSetup from './IntegrationSetup';
import IntegrationAlerts from './IntegrationAlerts';
import EditIntegration from './IntegrationEdit';
import ThankYouPage from './ai-interviewer/ThankYouPage';
import Wizard from './Wizard';
import TrainingCategorizationFeedbackLoopInterface from './TrainingCategorizationFeedbackLoopInterface';
import ConversationReview from './TrainingConversationReview';
import TrainingActivity from './TrainingActivity';
import StrategicReport from './StrategicReport';


const LoadingOverlay = ({ children }) => {
  const { loading } = useGlobalLoading();

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <h2>Loading your organization's conversations...</h2>
        <CircularProgressWithLabel value={100} />
      </div>
    );
  }

  return children;
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <GlobalLoadingProvider> {/* Wrap the app with global loading provider to show loading progress spinner while data loads */}
        <FilterProvider>
          <Router>
            <Routes>
              {/* Marketing Routes */}
              <Route path="/" element={<MarketingPage />} />

              {/* Public routes - redirects to a page defined in PublicRoute if logged in */}
              <Route path="/" element={<PublicRoute />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route path="forgot-password" element={<ForgotPasswordPage />} />
                <Route path="reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="interview/:projectId" element={<LoadingOverlay><InterviewPage /></LoadingOverlay>} />
                <Route path="thank-you" element={<ThankYouPage />} />
              </Route>

              {/* Private routes - redirects to login if not logged in */}
              <Route path="/" element={<PrivateRoute />}>
                <Route path="wizard" element={<LoadingOverlay><Wizard /></LoadingOverlay>} />
                <Route path="dashboard" element={<LoadingOverlay><PostLoginPage /></LoadingOverlay>} />
                <Route path="conversations" element={<LoadingOverlay><CustomerConversationsPage /></LoadingOverlay>} />
                <Route path="chosen-solutions" element={<LoadingOverlay><ChosenSolutionsPage /></LoadingOverlay>} />
                <Route path="triggers" element={<LoadingOverlay><TriggersPage /></LoadingOverlay>} />
                <Route path="previous-solutions" element={<LoadingOverlay><PreviousSolutionsPage /></LoadingOverlay>} />
                <Route path="considered-solutions" element={<LoadingOverlay><ConsideredSolutionsPage /></LoadingOverlay>} />
                <Route path="customer-needs" element={<LoadingOverlay><CustomerNeedsPage /></LoadingOverlay>} />
                <Route path="usage-insights" element={<LoadingOverlay><UsageInsightsPage /></LoadingOverlay>} />
                <Route path="/insight/:category" element={<LoadingOverlay><InsightDetails /></LoadingOverlay>} />
                <Route path="personas" element={<LoadingOverlay><PersonaProfiles /></LoadingOverlay>} />
                <Route path="journey-map" element={<LoadingOverlay><CustomerJourneyMapPage /></LoadingOverlay>} />
                <Route path="job-map" element={<LoadingOverlay><JobMapPage /></LoadingOverlay>} />
                <Route path="persona/:persona_id" element={<LoadingOverlay><PersonaProfile /></LoadingOverlay>} />
                <Route path="managedata" element={<LoadingOverlay><ManageData /></LoadingOverlay>} />
                <Route path="strategic-report" element={<LoadingOverlay><StrategicReport /></LoadingOverlay>} />
                {/* Integrations pages */}
                <Route path="/integrations" element={<IntegrationsPage />} />
                <Route path="/setup-integration/:integrationName" element={<IntegrationSetup />} />
                <Route path="/edit-integration/:integrationName" element={<EditIntegration />} />
                <Route path="/alerts" element={<IntegrationAlerts />} />
                {/* AI Interviewer pages */}
                <Route path="manage-projects" element={<LoadingOverlay><ManageProjects /></LoadingOverlay>} />
                <Route path="create-project" element={<LoadingOverlay><ProjectCreationFlow /></LoadingOverlay>} />
                <Route path="project-details/:projectId" element={<LoadingOverlay><ProjectDetails /></LoadingOverlay>} />
                {/* Feedback pages */}
                <Route path="ConversationReview" element={<LoadingOverlay><ConversationReview /></LoadingOverlay>} />
                <Route path="CategorizationFeedback" element={<LoadingOverlay><TrainingCategorizationFeedbackLoopInterface /></LoadingOverlay>} />
                <Route path="TrainingActivity" element={<LoadingOverlay><TrainingActivity /></LoadingOverlay>} />
              </Route>
            </Routes>
          </Router>
        </FilterProvider>
      </GlobalLoadingProvider>
    </LocalizationProvider>
  );
}

export default App;