import React, { useContext, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Tooltip,
  LinearProgress,
  Snackbar,
  Button,
} from '@mui/material';
import CheckBox from '@mui/material/Checkbox';
import { Icon } from '@mui/material';
import WordCloud from 'react-wordcloud';
import { LineChart, Line, XAxis, ResponsiveContainer } from 'recharts';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import { useNavigate } from 'react-router-dom';

const InsightsTrends = () => {
  const { filteredData } = useContext(DataAndFilterContext);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const dummyData = {
    opportunityScore: [1, 2, 3, 4, 5],
    revenueImpactAI: ['$20k', '$500k', '$1.2m', '$300k', '$700k'],
    revenueImpactSalesforce: ['$25k', '$550k', '$1.3m', '$320k', '$750k'],
  };

  const getSentimentColor = (value) => {
    const clampedValue = Math.max(1, Math.min(5, value));

    if (clampedValue <= 3) {
      const scale = (clampedValue - 1) / 2;
      const red = Math.round(255 - (255 - 128) * scale);
      const green = Math.round(0 + 128 * scale);
      const blue = Math.round(0 + 128 * scale);
      return `rgb(${red}, ${green}, ${blue})`;
    } else {
      const scale = (clampedValue - 3) / 2;
      const red = Math.round(128 - 128 * scale);
      const green = Math.round(128 + (255 - 128) * scale);
      const blue = Math.round(128 - 128 * scale);
      return `rgb(${red}, ${green}, ${blue})`;
    }
  };

  const toggleRowSelection = (category) => {
    const updatedSelection = selectedRows.includes(category)
      ? selectedRows.filter((row) => row !== category)
      : [...selectedRows, category];

    setSelectedRows(updatedSelection);
    setSnackbarOpen(updatedSelection.length > 0);
  };

  const handleClearSelected = () => {
    setSelectedRows([]);
    setSnackbarOpen(false);
  };

  const { insightStats, totalFiles } = useMemo(() => {
    const categoryStats = {};
    const totalFiles = filteredData.length;

    filteredData.forEach((file) => {
      const fileDate = new Date(file.conversation_date);
      const monthYear = `${fileDate.getFullYear()}-${fileDate.getMonth()}`;

      const addInsightCategory = (category, score, sentimentExpressed) => {
        if (!categoryStats[category]) {
          categoryStats[category] = {
            fileSet: new Set(),
            monthlyCounts: {},
            totalScore: 0,
            scoreCount: 0,
            sentiments: {},
          };
        }
        categoryStats[category].fileSet.add(file.filename);

        if (!categoryStats[category].monthlyCounts[monthYear]) {
          categoryStats[category].monthlyCounts[monthYear] = new Set();
        }
        categoryStats[category].monthlyCounts[monthYear].add(file.filename);

        if (score !== undefined) {
          categoryStats[category].totalScore += score;
          categoryStats[category].scoreCount += 1;
        }

        if (sentimentExpressed) {
          categoryStats[category].sentiments[sentimentExpressed] = 
            (categoryStats[category].sentiments[sentimentExpressed] || 0) + 1;
        }
      };

      file.jobs_to_be_done.forEach((job) => {
        job.insights?.forEach((insight) => {
          const category = insight.insight_category;
          const score = insight.insight_sentiment_score;
          const sentimentExpressed = insight.insight_sentiment_expressed;
          if (category) {
            addInsightCategory(category, score, sentimentExpressed);
          }
        });
      });

      file.miscellaneous_insights?.forEach((insight) => {
        const category = insight.insight_category;
        const score = insight.insight_sentiment_score;
        const sentimentExpressed = insight.insight_sentiment_expressed;
        if (category) {
          addInsightCategory(category, score, sentimentExpressed);
        }
      });
    });

    const insightStats = Object.entries(categoryStats).map(([category, stats]) => {
      const fileCount = stats.fileSet.size;
      const percentage = Math.round((fileCount / totalFiles) * 100);
      const monthlyData = Object.entries(stats.monthlyCounts).map(([month, files]) => ({
        month,
        count: files.size,
      }));

      const averageSentiment = stats.scoreCount ? (stats.totalScore / stats.scoreCount) : 5;

      const topSentiments = Object.entries(stats.sentiments)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 3)
        .map(([text, value]) => ({ text, value }));

      return {
        category,
        fileCount,
        percentage,
        monthlyData,
        averageSentiment,
        topSentiments,
      };
    });

    insightStats.sort((a, b) => b.fileCount - a.fileCount);

    return { insightStats, totalFiles };
  }, [filteredData]);

  const handleRowClick = (category) => {
    navigate(`/insight/${category}`);
  };

  const aggregateRevenueAI = selectedRows.length
    ? selectedRows
        .reduce((sum, category) => sum + parseFloat(dummyData.revenueImpactAI[selectedRows.indexOf(category)].replace(/[$k]/g, '')), 0)
        .toFixed(1) + 'k'
    : 0;

  const aggregateRevenueSalesforce = selectedRows.length
    ? selectedRows
        .reduce((sum, category) => sum + parseFloat(dummyData.revenueImpactSalesforce[selectedRows.indexOf(category)].replace(/[$k]/g, '')), 0)
        .toFixed(1) + 'k'
    : 0;

  return (
    <>
      <TableContainer component={Paper}>
        <Typography variant="h6" style={{ margin: '16px' }}>Insight Trends</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Insight Category</TableCell>
              <TableCell sx={{ width: '25%' }}>Total Conversations</TableCell>
              <TableCell>Trend Line</TableCell>
              <TableCell>Sentiment</TableCell>
              <TableCell>Top Emotions</TableCell>
              <TableCell>Opportunity Score</TableCell>
              <TableCell>
                <Tooltip title="Estimated revenue impact calculated using AI." arrow>
                  <span>Revenue Impact - AI</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Revenue impact pulled directly from Salesforce for customers related to this insight." arrow>
                  <span>
                    Revenue Impact - Salesforce
                    <Icon style={{ fontSize: '16px', marginLeft: '4px' }}>cloud</Icon>
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell>Add to Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {insightStats.map((insight, index) => (
              <TableRow key={index} onClick={() => handleRowClick(insight.category)} style={{ cursor: 'pointer' }}>
                <TableCell>{insight.category}</TableCell>
                <TableCell sx={{ width: '25%' }}>
                  <Box display="flex" alignItems="center">
                    <Box width={`${(insight.fileCount / totalFiles) * 100}%`} height="24px" bgcolor="#8884d8" />
                    <Typography style={{ marginLeft: '8px' }}>
                      {insight.fileCount} ({insight.percentage}%)
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <ResponsiveContainer width={100} height={30}>
                    <LineChart data={insight.monthlyData}>
                      <Line type="monotone" dataKey="count" stroke="#8884d8" dot={false} />
                      <XAxis dataKey="month" hide />
                    </LineChart>
                  </ResponsiveContainer>
                </TableCell>
                <TableCell>
                  <Box sx={{ position: 'relative' }}>
                    <LinearProgress
                      variant="determinate"
                      value={(insight.averageSentiment / 5) * 100}
                      sx={{
                        height: '10px',
                        borderRadius: '5px',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: getSentimentColor(insight.averageSentiment),
                        },
                      }}
                    />
                    <Typography variant="body2" style={{ textAlign: 'center', marginTop: '4px' }}>
                      {insight.averageSentiment.toFixed(1)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ height: '50px', width: '95px', overflow: 'hidden' }}>
                    <WordCloud
                      words={insight.topSentiments}
                      options={{
                        fontSizes: [10, 15],
                        rotations: 0,
                        padding: 1,
                        enableOptimizations: true,
                      }}
                      style={{ height: '60px', width: '100px' }}
                    />
                  </Box>
                </TableCell>
                <TableCell>{"$".repeat(dummyData.opportunityScore[index])}</TableCell>
                <TableCell>{dummyData.revenueImpactAI[index]}</TableCell>
                <TableCell>{dummyData.revenueImpactSalesforce[index]}</TableCell>
                <TableCell>
                  <CheckBox
                    checked={selectedRows.includes(insight.category)}
                    onChange={() => toggleRowSelection(insight.category)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbarOpen}
        message={`Aggregated ARR Impact: AI - ${aggregateRevenueAI}, Salesforce - ${aggregateRevenueSalesforce}`}
        action={
          <>
            <Button color="primary" size="small" onClick={() => alert('Added to report')}>
              Add to Report
            </Button>
            <Button color="secondary" size="small" onClick={handleClearSelected}>
              Clear Selected
            </Button>
          </>
        }
      />
    </>
  );
};

export default InsightsTrends;
